import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import loadable from '@loadable/component'
import Layout from '../components/layout';
import CategoryTitle from "../components/helpers/categoryTitle";
import Helmet from "react-helmet";
import {metadata} from "../../config";

const PopularPosts = loadable(() => import("../components/popularPosts"));
const ArticlePreview = loadable(() => import("./articlePreview"));
const AdRightSticky = loadable(() => import("../components/ads/adRightSticky"));
const AdLeftSticky = loadable(() => import("../components/ads/adLeftSticky"));

const Homepage = props => {
    const getHeroArticle = () => {
        let featuredArticle = (props.data.datoCMS.featured || []).find(article => new Date(article['featuredTill']) >= new Date()) || props.data.datoCMS.topRight[0];
        //setHeroArticle(featuredArticle);
        displayedArticles.push(featuredArticle.slug);
        return featuredArticle;
    };

    const displayedArticles = [];
    const [heroArticle, setHeroArticle] = useState(getHeroArticle());

    const schemaArticle = {
        '@context': 'https://schema.org',
        '@type': 'Website',
        "url": metadata.url,
        "name": metadata.defaultTitle,
        "description": metadata.description,
        "potentialAction": {
            "@type": "SearchAction",
            "target": `${metadata.url}/search/{search_term_string}`,
            "query-input": "required name=search_term_string"
        }
    }

    const excludeAlreadyDisplayed = (articles) => {
        return articles.filter(article => !displayedArticles.includes(article.id));
    }

    return (
        <Layout>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>
            </Helmet>
            <main className={"wrapper wrapper--default wrapper--article"} data-datocms-noindex>
                <AdLeftSticky/>
                <div className={"container container-grid-unwrap index-top-level-container"}>
                    <div className="left-container">
                        <div className="container container--halves-uneven container--lined top">
                            <div className="container-grid">
                                <div className="container-grid__item">
                                    {
                                        props.data.datoCMS.topLeft.map((article, index) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview
                                                customClass="bottom-border" key={`top-left-${index}`} data={article}
                                                noImage={true}/>
                                        })
                                    }
                                </div>
                                <div className="container-grid__item">
                                    <ArticlePreview customClass={"big-heading"}
                                                    imageMeta={{'max-w': 850, 'q': 20, 'fit': 'crop'}}
                                                    lazyLoad={false}
                                                    key={`heroArticle`}
                                                    data={heroArticle}/>
                                </div>
                            </div>
                        </div>
                        <div className="container container--thirds container--lined center">
                            <CategoryTitle link="/finance" title="Finance"/>
                            <div className={"container-grid"}>
                                {
                                    excludeAlreadyDisplayed(props.data.datoCMS.center).slice(0, 3).map((article, index) => {
                                        displayedArticles.push(article.id);
                                        return <div className={"container-grid__item"} key={`center-${index}`}>
                                            <ArticlePreview
                                                data={article}
                                                noFlag={true}
                                                imageMeta={{width: 300}}/>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="container container--halves-uneven container--lined second-bottom">
                            <div className="container-grid">
                                <div className="container-grid__item">
                                    <CategoryTitle link="/culture" title="Culture"/>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottomLeft).slice(0, 1).map((article, index) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview
                                                customClass="big-heading" key={`bottomLeft-${index}`} noFlag={true}
                                                data={article} imageMeta={{width: 768}}/>
                                        })
                                    }
                                </div>
                                <div className="container-grid__item">
                                    <CategoryTitle link="/lifestyle" title="LIFESTYLE"/>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottomRight).slice(0, 2).map((article, index) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview
                                                customClass="bottom-border" key={`bottomRight-${index}`} noFlag={true}
                                                data={article}
                                                noImage={index !== 0}
                                                imageMeta={{width: 768}}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="container container--thirds container--lined bottom">
                            <CategoryTitle link="/health-wellbeing" title="HEALTH & WELLBEING"/>
                            <div className="container-grid">
                                <div className={"container-grid__item first"}>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottom).slice(0, 1).map((article, index) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview key={`bottom-first-${index}`}
                                                                   data={article}
                                                                   noFlag={true}
                                                                   imageMeta={{width: 300}}/>
                                        })
                                    }
                                </div>
                                <div className={"container-grid__item"}>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottom).slice(0, 2).map((article, index) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview key={`bottom-second-${index}`}
                                                                   data={article}
                                                                   customClass="teaser-card--right-aligned-img"
                                                                   noFlag={true}
                                                                   wrappedImg={true}
                                                                   imageMeta={{width: 300}}/>
                                        })
                                    }
                                </div>
                                <div className={"container-grid__item"}>
                                    {
                                        excludeAlreadyDisplayed(props.data.datoCMS.bottom).slice(0, 2).map((article, index) => {
                                            displayedArticles.push(article.id);
                                            return <ArticlePreview key={`bottom-third-${index}`}
                                                                   data={article}
                                                                   customClass="teaser-card--right-aligned-img"
                                                                   noFlag={true}
                                                                   wrappedImg={true}/>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <PopularPosts/>
                </div>
                <AdRightSticky/>
            </main>
        </Layout>
    )
}

export const query = graphql`
    query indexPageTopLeft($top: [DatoCMS_ItemId], $center: [DatoCMS_ItemId], $bottomLeft: [DatoCMS_ItemId], $bottomRight: [DatoCMS_ItemId], $bottom: [DatoCMS_ItemId], $currentDateTime: DatoCMS_DateTime) {
        datoCMS {
            featured: allArticles(filter: {featuredTill: {gte: $currentDateTime}}, orderBy: publishedAt_DESC) {
                ...ArticleFieldsWithBigImage
            }
            topLeft: allArticles(filter: {category: {anyIn: $top}}, first: "3", skip: "1", orderBy: publishedAt_DESC) {
                ...ArticleFields
            }
            topRight: allArticles(filter: {category: {anyIn: $top}}, first: "4", orderBy: publishedAt_DESC) {
                ...ArticleFieldsWithBigImage
            }
            center: allArticles(filter: {category: {anyIn: $center}}, first: "10", orderBy: publishedAt_DESC) {
                ...ArticleFields
            }
            bottomLeft: allArticles(filter: {category: {anyIn: $bottomLeft}}, first: "11", orderBy: publishedAt_DESC) {
                ...ArticleFieldsWithBigImage
            }
            bottomRight: allArticles(filter: {category: {anyIn: $bottomRight}}, first: "14", orderBy: publishedAt_DESC) {
                ...ArticleFields
            }
            bottom: allArticles(filter: {category: {anyIn: $bottom}}, first: "20", orderBy: publishedAt_DESC) {
                ...ArticleFields
            }
        }
    }
`

export default Homepage
